import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import "./all.sass";
import useSiteMetadata from "../helper/SiteMetadata";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import LangProvider from "../context/langContext";
import GoogleTagManager from "../helper/GoogleTagManager";
import ReactGA from "react-ga";
import Cookies from "js-cookie";
import { initGA, logPageView } from "../helper/GoogleAnalytics";
const Navbar = loadable(() => import("./Navbar/Navbar"));
const Footer = loadable(() => pMinDelay(import("./Footer/Footer"), 400));
const CookieConsentComponent = loadable(() =>
  pMinDelay(import("./CookieConsent/CookieConsent"), 2000)
);
const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const [scrolled, setScrolled] = useState("");
  let scrolledWindow = typeof window !== "undefined" ? window.scrollY : null;
  let googleAnalysisCookie = Cookies.get("gatsby-gdpr-google-analytics");
  useEffect(() => {
    if (scrolledWindow !== null) {
      if (googleAnalysisCookie) {
        if (!window.GA_INITIALIZED) {
          initGA()
          window.GA_INITIALIZED = true
        }
        logPageView()
      }
    }

  }, [googleAnalysisCookie]);

  return (
    <LangProvider>
      <Helmet>
        <html lang="hu" />
        <meta charset="UTF-8" />
        <meta name="description" content={description} />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:description" content="/" />
        <meta property="og:image" content="/img/logo_dark.png" />
      </Helmet>
      <Navbar scrolled={scrolled} />
      <div>{children}</div>
      <div></div>
      <Footer />
      <CookieConsentComponent />
    </LangProvider>
  );
};

export default TemplateWrapper;

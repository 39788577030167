  
import ReactGA from "react-ga"

export const initGA = () => {
  ReactGA.initialize("UA-167329474-1",{
    debug: false,
  })
}
 const pathname =  typeof window !== "undefined" ? window.location.pathname : null
export const logPageView = () => {
  ReactGA.set({ page:  pathname})
  ReactGA.pageview(pathname)
} 